import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

import pic1 from "../pictures/pic1.webp";
import pic2 from "../pictures/banner-2.webp";
import pic5 from "../pictures/pic5.webp";
import pic3 from "../pictures/pic3.webp";
import pic4 from "../pictures/pic4.webp";

const pictures = [pic5, pic2, pic1, pic4, pic3, pic5, pic2, pic1, pic4, pic3];

const Home = () => {
  const [currentPictureIndex, setCurrentPictureIndex] = useState(0);

  const handleEmailClick = () => {
    const email = "info@nimantran.se";
    window.location.href = `mailto:${email}`;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPictureIndex((prevIndex) => (prevIndex + 1) % pictures.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    //h-screen
    <div>
      <section className="flex items-center justify-center my-4 max-w-full h-auto">
        <AnimatePresence mode="wait">
          <motion.img
            key={currentPictureIndex}
            src={pictures[currentPictureIndex]}
            alt="Landing Picture"
            className="rounded-lg shadow-xl shadow-amber-900/40 h-full max-w-full" //h-full
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={variants}
            transition={{ duration: 0.9 }}
          />
        </AnimatePresence>
      </section>

      <section className="py-12">
        <div className="container mx-auto">
          <motion.div
            className="flex items-center justify-center"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <h2 className="text-3xl font-semibold text-red-950 drop-shadow-xl">
              Välkommen till{" "}
              <span className="before:block before:absolute before:-inset-1 before:-skew-y-3 before:bg-red-950 relative inline-block">
                <span className="relative text-white italic">Svansjöhus!</span>
              </span>
            </h2>
          </motion.div>

          <motion.div
            className="mt-8 ml-auto mr-auto mb-4"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            <div className="bg-gray-100 p-6 rounded-lg md:w-3/5 w-4/5 mx-auto shadow-lg shadow-amber-900/40 bg-opacity-80 px-6 md:px-12 pb-8 md:pb-12">

              <br />
              <ul className="list-disc list-inside text-justify text-font mt-2">
                <li><b>Välkommen till Svansjöhus – den perfekta lokalen för dig i Skurup</b><br></br>I hjärtat av Skåne ligger Svansjöhus, omgivet av vacker vild natur. Detta är den perfekta platsen för dina event, oavsett om du planerar ett bröllop, en födelsedagsfest, en kick-off med jobbet eller ett event med ditt team.</li>
                <br />
                <li>
                  <b>En mångsidig lokal för alla tillfällen</b><br></br>Vi på Svansjöhus förstår att alla event är unika. Vår rymliga lokal kan husera upp till 100 gäster, vilket gör den idealisk för både mindre och större event. Vår lokal passar oavsett om du ska ha ett bröllop i kärlekens tecken, en rolig fest eller en professionell konferens.
                </li>
                <br />
                <li>
                  <b>Ta med din egen mat och dryck</b><br></br>En av de saker som gör oss på Svansjöhus unika är att du tar med dig egen mat och dryck. Den här flexibiliteten gör det möjligt för dig att göra dina event mer personliga efter vad som passar dina smaklökar, oavsett vad för typ av cuisine du vill servera.
                </li>
                <br />
                <li>
                  <b>Bra läge</b><br></br>Svansjöhus ligger ungefär 500 meter från historiska Svaneholms slott. Vår lokal ligger fem minuter med bil från ett köpcenter, vilket gör det enkelt för dig att införskaffa allt du behöver till ditt event. Svansjöhus ligger bara 30 minuter med bil från Malmö och 40 minuter med bil från Lund, vilket gör det enkelt för dina gäster att ta sig till oss. Det finns även en tågstation ca 3 km från lokalen.
                </li>
                <br />
                <li>
                  <b>Dina behov kommer i första rum</b><br></br>Vår erfarna eventmanager är mån om att ditt event blir som planerat och finns där för dig från planeringsstadiet till slutet. Vi tipsar gärna om cateringfirmor, florister och liknande för att din dag ska bli så speciell som du har tänkt dig.
                </li>
                <br />
                <li>
                  <b>Skapa minnen på Svansjöhus</b><br></br>Oavsett om du firar en milstolpe i livet, ska ha ett jobbevent eller träffa dina nära och kära finns Svansjöhus lugna och inbjudande miljö till ditt förfogande. Tack vare naturens skönhet, moderna bekvämligheter och personliga service blir ditt event på Svansjöhus något värt att minnas.
                </li>
                <br />
              </ul >
            </div >
          </motion.div >
        </div >
      </section >
    </div >
  );
};

export default Home;
