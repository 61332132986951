import React from "react";
import { motion } from "framer-motion";
import Map from "./Components/Map";
import "./custom.css";
const About = () => {
  return (
    <div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-4xl font-bold text-red-900 drop-shadow-xl mt-6 mb-2">
            Om oss
          </h1>
          <h6 className="text-4xl font-bold text-red-900 drop-shadow-xl mt-6 mb-2">
            Svansjöhus – En perfekt blandning av natur och elegans
          </h6>
          <p className="mt-4 text-font text-lg drop-shadow-lg p-width">
            Svansjöhus är en charmig eventlokal som ligger mitt i pittoreska Skurup i södra Sverige. Omgiven av fantastisk vild natur och bara 500 meter från det historiska Svaneholms slott erbjuder vi en unik och rofylld miljö för alla typer av evenemang. Oavsett om du anordnar ett bröllop, en födelsedagsfest, ett företagsevenemang eller en konferens är Svansjöhus den perfekta platsen för att skapa oförglömliga minnen.

            Med plats för upp till 100 gäster är lokalen utformad för att passa både intima sammankomster och större firanden. Lokalen är fullt utrustad med alla bekvämligheter du kan tänkas behöva och vi är flexibla, vilket säkerställer att ditt evenemang stämmer överens med dina önskemål. En extra fördel är att gästerna har friheten att ta med sin egen mat och dryck.

            Oavsett om du vill skapa minnen för livet eller ska planera ett jobbevent finns vi på Svansjöhus här för dig.
          </p>
        </div>
      </motion.div >
      <div className="h-56 w-full mb-64 md:mb-64">
        <Map />
      </div>
    </div >
  );
};

export default About;
