import React, { useState } from "react";
import "./FAQ.css";
import "./custom.css";
import home from "../pictures/home.jpg";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "Får jag ta med mig mat och dryck?",
      answer: "Ja, det får du. Även alkoholhaltiga drycker.",
    },
    {
      question: "Får jag ta med min hund till Svansjöhus?",
      answer:
        "Ja, hundar är välkomna både utomhus och inomhus.",
    },
    {
      question: "Är det möjligt att ta tåget till Svansjöhus?",
      answer: "Ja, tågstationen ligger ca 3 km bort.",
    },
    {
      question: "Hur länge får festen pågå?",
      answer: "Ni får hålla i gång så länge ni önskar, men tänk på att skruva ner musiken och stänga fönster samt dörrar runt 00.00 för att inte störa omgivningen.",
    },
    {
      question: "Vad ingår i priset?",
      answer: "Stolar och bord Porslin (tallrikar, glas, bestick o.s.v.) Diskmaskin Högtalare och mikrofon Handdukar, tvål och toalettpapper projektor, kaffemaskin, kaffefilter, kaffetermosar, vattenkaraffer, köksknivar, flasköppnare, skärbrädor saxar. OBS! Vi serverar ingenting ätbart eller drickbart, inte ens salt och peppar.",
    },
    {
      question: "Kan jag få tips om leverantörer?",
      answer: "Absolut! Vi har rekommendationer till dig vad gäller husrum, catering, enklare personal, fotografer och mer. Du kan mejla oss för att få en lista med förslag.",
    },
    {
      question: "Hur många kylar och frysar finns det?",
      answer: "Vi har en stor kyl och en stor frys. Vi har även en mindre kyl med ett frysfack, så det finns gott om plats.",
    },
    {
      question: "Vad är det för högtalare som inkluderas i priset?",
      answer: "Högtalare och mikrofon (med sladd) ingår i priset. Du kan ansluta till högtalarna via Bluetooth och du får använda högtalarna utomhus så länge de står under tak och skyddas från väder. Kontrollera gärna när du besöker vår lokal om högtalarna når den standard du vill ha.",
    },
    {
      question: "Vad ingår i städningen?",
      answer: "Vi städar golv och alla ytor. Det enda du behöver göra är att ta med dina ägodelar från lokalen, flytta tillbaka de möbler du eventuellt flyttat och ta hand om disken (de flesta ber cateringfirman att hantera disken).",
    },
    {
      question: "Får jag ha konfetti?",
      answer: "Nej, tyvärr inte. Varken inomhus eller utomhus. Undantag kan göras om det rör sig om ris eller liknande utomhus.",
    },
    {
      question: "Finns det wifi?",
      answer: "Ja, det finns gratis wifi.",
    },
    {
      question: "Får jag lov att använda rökmaskin?",
      answer: "Nej, det får du inte på grund av brandvarnarna.",
    },
    {
      question: "Får jag sätta upp ett partytält i trädgården?",
      answer: "Ja, det får du göra.",
    },
    {
      question: "Finns det en bardisk?",
      answer: "Ja, det finns det i vårt lusthus.",
    },
    {
      question: "Finns det en parkeringsplats?",
      answer: "Ja, det finns det! Notera att du inte får parkera på gräset eller längs vägen, endast på vår grusplan.",
    },
    {
      question: "Får jag ha livemusik och/eller DJ?",
      answer: "Ja, det får du gärna ha, men tänk på att skruva ner musiken och stänga fönster samt dörrar runt 00.00 för att inte störa omgivningen.",
    },
    {
      question: "Finn det ett ljudsystem på plats?",
      answer: "Ja, ett enklare ljudsystem finns på plats (Bluetooth) och en mikrofon ingår i priset.",
    },
    {
      question: "Får jag dekorera lokalen?",
      answer: "Ja, du får gärna dekorera. Tänk på att det är förbjudet att använda spikar eller dylikt som kan orsaka skador på väggar och andra ytor.",
    },
    {
      question: "Får jag ha levande ljus i lokalen?",
      answer: "Ja, men du måste vidta försiktighetsåtgärder som att inte tända dem nära lättantändliga material. LED-ljus är ett bra alternativ som vi rekommenderar.",
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  // Divide FAQs into two columns
  const middleIndex = Math.ceil(faqs.length / 2);
  const column1 = faqs.slice(0, middleIndex);
  const column2 = faqs.slice(middleIndex);

  return (
    <div className="faq-container p-width">
      <h1>Frequently Asked Questions</h1>
      <div className="faq-columns">
        {[column1, column2].map((column, columnIndex) => (
          <div key={columnIndex} className="faq-column">
            {column.map((faq, index) => (
              <div
                key={`${columnIndex}-${index}`}
                className={`faq-item ${activeIndex === `${columnIndex}-${index}` ? "open" : ""}`}
              >
                <div
                  className="faq-question"
                  onClick={() => toggleFAQ(`${columnIndex}-${index}`)}
                >
                  {faq.question}
                  <span className="arrow">
                    {activeIndex === `${columnIndex}-${index}` ? "▲" : "▼"}
                  </span>
                </div>
                <div
                  className="faq-answer"
                  style={{
                    maxHeight: activeIndex === `${columnIndex}-${index}` ? "150px" : "0",
                    overflow: "hidden",
                  }}
                >
                  <p>{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;